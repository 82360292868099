import React, { useState, useEffect } from "react";
import axios from "axios";

export default function FontStyleSelector() {
  const [selectedFonts, setSelectedFonts] = useState([]);
  const [currentFont, setCurrentFont] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [customFont, setCustomFont] = useState("");
  const [allStyles, setAllStyles] = useState([]);
  const fontStyles = [
    ...allStyles,
    "Arial",
    "Helvetica",
    "Times New Roman",
    "Courier",
    "Verdana",
    "Georgia",
    "Palatino",
    "Garamond",
    "Bookman",
    "Comic Sans MS",
    "Trebuchet MS",
    "Arial Black",
    "Impact",
  ];
  const addFont = () => {
    if (currentFont && !selectedFonts.includes(currentFont)) {
      loadFont(currentFont);
      setSelectedFonts([...selectedFonts, currentFont]);
      setCurrentFont("");
    }
  };
  const addCustomFont = () => {
    if (customFont && !selectedFonts.includes(customFont)) {
      loadFont(customFont);
      setSelectedFonts([...selectedFonts, customFont]);
      setCustomFont("");
    }
  };

  const deleteFont = (fontToDelete) => {
    setSelectedFonts(selectedFonts.filter((font) => font !== fontToDelete));
  };

  const availableFonts = fontStyles.filter(
    (font) =>
      !selectedFonts.includes(font) &&
      font.toLowerCase().includes(searchQuery.toLowerCase())
  );
  useEffect(() => {
    axios
      .get(
        "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBMjiQm1vzupPH1LqIb240JP2smnC5BBpE&sort=popularity"
      )
      .then((res) => {
        // const items = res?.data?.items;
        // const uniqueCategories = [
        //   ...new Set(items.map((item) => item.category)),
        // ];
        // const filteredItems = uniqueCategories.map((category) =>
        //   items.find((item) => item.category === category)
        // );
        setAllStyles(res?.data?.items?.slice(0, 25).map((item) => item.family)); // console.log(filteredItems);
      });
  }, []);
  const loadFont = (font) => {
    const fontLink = document.createElement("link");
    fontLink.href = `https://fonts.googleapis.com/css2?family=${font.replace(
      / /g,
      "+"
    )}&display=swap`;
    fontLink.rel = "stylesheet";
    document.head.appendChild(fontLink);
  };
  return (
    <div className="font-style-selector">
      <h2>Font Style Selector</h2>
      <div className="color-picker-controls">
        <input
          type="text"
          placeholder="Search fonts..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="font-selector-controls">
        <select
          value={currentFont}
          onChange={(e) => setCurrentFont(e.target.value)}
        >
          <option value="">Select a font</option>
          {availableFonts.map((font) => (
            <option key={font} value={font}>
              {font}
            </option>
          ))}
        </select>
        <button onClick={addFont}>Add Font</button>
      </div>
      <div className="color-picker-controls">
        <input
          type="text"
          placeholder="Enter custom font..."
          value={customFont}
          onChange={(e) => setCustomFont(e.target.value)}
        />
        <button onClick={addCustomFont}>Add Custom Font</button>
      </div>
      <ul className="font-list">
        {selectedFonts.map((font, index) => (
          <li key={index}>
            <div className="font-item">
              <span style={{ fontFamily: font }}>{font}</span>
              <button onClick={() => deleteFont(font)}>Delete</button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
