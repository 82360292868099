import { deleteMethod } from "../Utils/ApiServices";

const deletedCategoryApi = async (id) => {
  const returnFlag = await deleteMethod("category/category?categoryId=" + id);
  return returnFlag;
};
const deletedProductApi = async (id) => {
  const returnFlag = await deleteMethod(
    "product-type/producttype?productTypeId=" + id
  );
  return returnFlag;
};
const deletedTemplateApi = async (id) => {
  const returnFlag = await deleteMethod("template/delete?templateId=" + id);
  return returnFlag;
};

const deleteComponentIdDemo = async (id) => {
  const returnFlag = await deleteMethod(
    "admin/delete/component?componentId=" + id
  );
  return returnFlag;
};
const deleteColor = async (id) => {
  const returnFlag = await deleteMethod("admin/deleteColor?id=" + id);
  return returnFlag;
};
const deletemember = async (data) => {
  const returnFlag = await deleteMethod(
    "/user/members/" + data.data + "?ownerId=" + data.id
  );
  return returnFlag;
};

const deletePricingPlan = async (id) => {
  const returnFlag = await deleteMethod("pricing/bundle?pricingBundleId=" + id);
  return returnFlag;
};
const deletePlans = async (id) => {
  const returnFlag = await deleteMethod("/plans/" + id);
  return returnFlag;
};

const deleteCoupon = async (id) => {
  const returnFlag = await deleteMethod("coupon/deletecoupon?couponId=" + id);
  return returnFlag;
};

const deleteUserAccount = async (id) => {
  const returnFlag = await deleteMethod("admin/accountdelete/" + id);
  return returnFlag;
};

const deleteOrderDetails = async (id) => {
  const returnFlag = await deleteMethod("admin/delete/order?canvasId=" + id);
  return returnFlag;
};
const deletedShowCaseApi = async (id) => {
  const returnFlag = await deleteMethod("showCase/" + id);
  return returnFlag;
};
const deleteComponent = async (id) => {
  const returnFlag = await deleteMethod(
    "admin/delete/component?componentId=" + id
  );
  return returnFlag;
};
export {
  deleteColor,
  deletedCategoryApi,
  deleteComponent,
  deletedProductApi,
  deletePlans,
  deletedTemplateApi,
  deleteComponentIdDemo,
  deletePricingPlan,
  deletemember,
  deleteCoupon,
  deleteUserAccount,
  deleteOrderDetails,
  deletedShowCaseApi,
};
