import React, { useEffect, useState } from "react";
import {
  getAllOrder,
  getAllOrderSort,
  getRecipientsDetails,
  getBundlelistall,
} from "../Action/GetAction";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { dateFormatConverter } from "../Logic/Logic";
import "react-overlay-loader/styles.css";
import { useHistory, useLocation } from "react-router";
import {
  getMethod,
  postMethod,
  putMethod,
  WEB_URL,
} from "../Utils/ApiServices";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { deleteOrderDetails } from "../Action/DeleteAction";
import { toastComp } from "../Sections/Toast";
import { useToasts } from "react-toast-notifications";
import { DefinedRange } from "react-date-range";
import { DateRangePicker } from "react-date-range";
import { addDays, subDays } from "date-fns";
import { ExportToExcel } from "../tools/ExportToExcel";
import { format } from "fecha";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { getCookies } from "../Utils/Cookies";
import { confirmAlert } from "react-confirm-alert";
import MyTable from "./datatable.js";

const Bundlelist = (props) => {
  const [token, setToken] = useState(
    getCookies("_gp_admin_token") ? getCookies("_gp_admin_token") : ""
  );
  const [pathRoutes, setPathRoutes] = useState(
    props?.location?.pathname === "/bundle-orders"
      ? "bundles"
      : props?.location?.pathname === "/card-orders"
      ? "cards"
      : "orders"
  );
  const limitData = 1000000;
  const { addToast } = useToasts();
  const [toggleId, setToggleId] = useState("bundles");
  const [allOrderList, setOrderList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pageChange, setPageNow] = useState(1);
  const [totalList, setTotalList] = useState(0);
  const [statusSort, setStatusSort] = useState("");
  const [paginationIndex, setPageIndex] = useState(0);
  const [searchPage, setSearchPage] = useState();
  const [Bundlelistall, SetBundlelistall] = useState([]);
  const [allData, setallData] = useState([]);
  const [filterToggle, setFilterToggle] = useState(false);
  const [stateFilters, setDateFilters] = useState([
    {
      startDate: subDays(new Date(), 4),
      endDate: addDays(new Date(), 2),
      key: "selection",
      //             startDate: 2022-02-12
      // endDate: 2022-02-19
    },
  ]);

  const sendCardToRecipient = async (order) => {
    if (order) {
      let orderId = order._id;
      let data = {
        orderId: orderId,
      };
      await postMethod("/cardDeliveredByAdmin", data).then((res) => {
        if (res.success) {
          toastComp(addToast, "success", res.message);
        } else {
          toastComp(addToast, "error", res.message);
        }
      });
    }
  };

  // const getOrderBundlesList = async (page, limit, value) => {
  //     var finalData = { page: page, limit: limit };
  //     if (value?.startDate && value?.endDate) {
  //         var finalData = Object.assign(finalData, { startDate: value?.startDate, endDate: value?.endDate })
  //     }
  //     setLoader(true);
  //     await getMethod('admin/allbundles', finalData).then(response => {
  //         if (response.success) {
  //             setTotalList(response?.total)
  //             setPageIndex(response?.total < limitData ? 1 : (Math.round((response?.total) / limitData)) % 2 === 0 ? (Math.round((response?.total) / limitData)) : ((Math.round((response?.total) / limitData))));
  //             setOrderList(response?.data);
  //             setLoader(false)
  //         } else {
  //             setLoader(false)
  //         }
  //     })
  // }

  // const getOrderCardsList = async (page, limit, value, status) => {
  //     var finalData = { page: page, limit: limit }
  //     if (value?.startDate && value?.endDate) {
  //         finalData = Object.assign(finalData, { startDate: value?.startDate, endDate: value?.endDate });
  //     }
  //     // if (status) {
  //     //     finalData = Object.assign(finalData, { status: status });
  //     // }
  //     if (status === "false") {
  //         finalData = Object.assign(finalData, { cardOpened: status, status:"complete"});
  //     }else if (status) {
  //         finalData = Object.assign(finalData, { status: status });
  //     }

  //     setOrderList([]);
  //     setLoader(true);
  //     await getMethod('admin/order', finalData).then(response => {
  //         if (response.success) {
  //             setTotalList(response?.total);
  //             setPageIndex(response?.total < limitData ? 1 : (Math.round((response?.total) / limitData)) % 2 === 0 ? (Math.round((response?.total) / limitData)) : ((Math.round((response?.total) / limitData))));
  //             setOrderList(response?.data);
  //             setLoader(false)
  //         } else {
  //             setLoader(false)
  //         }
  //     })
  // }

  const filtersDate = async (path, page, limit, data, statusSort) => {
    var passData = false;
    if (data) {
      setDateFilters(data);
      passData = {
        startDate: JSON.stringify(new Date(data[0]?.startDate)).slice(1, 11),
        endDate: JSON.stringify(new Date(data[0]?.endDate)).slice(1, 11),
      };
    }
    if (path === "bundles") {
      //console.log(passData)
      // // setSearchPage(0);
      // getOrderBundlesList(page, limitData, passData);
    }
    if (path === "cards") {
      //console.log(passData)
      // setSearchPage(0);
      // getOrderCardsList(page, limit, passData, statusSort);
    }
  };

  const hstatusSort = async (value) => {
    if (pathRoutes === "cards") {
      setSearchPage(0);
      filtersDate(toggleId, 1, limitData, stateFilters, value);
    }
    setStatusSort(value);
  };

  const handlePageClick = async (e) => {
    console.log("clicked");
    const selectedPage = parseInt(e.selected + 1);

    setLoader(true);
    setPageNow(selectedPage);
  };

  const resetButton = async () => {
    setStatusSort("");
    const data = {
      startDate: subDays(new Date(), 4),
      endDate: addDays(new Date(), 2),
      key: "selection",
    };
    setDateFilters([data]);
    filtersDate(toggleId, 1, limitData, [data], "");
    setFilterToggle(filterToggle ? false : true);
  };
  var divisor = (totalList / 30) >> 0;
  var remainder = totalList % 30;

  useEffect(() => {
    setPathRoutes(toggleId);
    filtersDate(toggleId, pageChange, limitData, stateFilters, statusSort);
    setToken(getCookies("_gp_admin_token"));
    getOrderBundlesListall(pageChange, limitData);
  }, [props]);
  useEffect(() => {
    getOrderBundlesListall(pageChange, limitData);
  }, [pageChange]);

  const getOrderBundlesListall = async (page, limitdata) => {
    setLoader(true);
    await getBundlelistall(page, limitdata).then((response) => {
      // console.log("response bundle", response);
      if (response.status) {
        setallData(response?.data);
        SetBundlelistall(response.data);
        setTotalList(response.totalCount);
        setPageIndex(Math.ceil(response.totalCount / limitdata));
        setLoader(false);
      } else {
      }
    });
  };
  const [search, setSearch] = useState("");
  const handleSubmitSearch = async (e) => {
    e.preventDefault();
    const { search } = e.target.elements;
    console.log("clicked");
  };

  const [sortedData, setSortedData] = useState(Bundlelistall);
  const [sortOption, setSortOption] = useState("All");
  useEffect(() => {
    setSortedData(Bundlelistall);
  }, [Bundlelistall]);

  // const sortbychange = (value) => {
  //   console.log(value,'cliked')
  //   const option =value;
  //   setSortOption(option);
  //   localStorage.setItem('sortOption', option);

  //   let sortedArray = [...Bundlelistall];

  //   if (option === "userName") {
  //     sortedArray.sort((a, b) => {
  //       const firstNameA = a.userId ? a.userId.firstName : "Guest";
  //       const firstNameB = b.userId ? b.userId.firstName : "Guest";
  //       return firstNameA.localeCompare(firstNameB);
  //     });
  //   } else if (option === "cardbalance") {
  //     sortedArray.sort((a, b) => {
  //       const balanceA = Number(a.userId?.wallet);
  //   const balanceB = Number(b.userId?.wallet);

  //   if (balanceA === balanceB) {
  //     return 0;
  //   }
  //   return balanceA - balanceB;
  //     });
  //   } else {
  //     sortedArray = [...Bundlelistall];
  //   }

  //   setSortedData(sortedArray);
  // };
  const sortbychange = (value) => {
    console.log(value, "clicked");
    const option = value;
    setSortOption(option);
    localStorage.setItem("sortOption", option);

    let sortedArray = [...Bundlelistall];

    if (option === "userName") {
      sortedArray.sort((a, b) => {
        const firstNameA = a.userId ? a.userId.firstName : "Guest";
        const firstNameB = b.userId ? b.userId.firstName : "Guest";
        return firstNameA.localeCompare(firstNameB);
      });
    } else if (option === "userEmail") {
      sortedArray.sort((a, b) => {
        const emailA = a.userId ? a.userId.email : "";
        const emailB = b.userId ? b.userId.email : "";
        return emailA.localeCompare(emailB);
      });
    } else if (option === "cardbalance") {
      sortedArray.sort((a, b) => {
        const balanceA = Number(a.userId?.wallet);
        const balanceB = Number(b.userId?.wallet);

        if (balanceA === balanceB) {
          return 0;
        }
        return balanceA - balanceB;
      });
    } else if (option === "amountPaid") {
      sortedArray.sort((a, b) => {
        const amountA = Number(a?.paymentId?.amount || 0);
        const amountB = Number(b?.paymentId?.amount || 0);

        if (amountA === amountB) {
          return 0;
        }
        return amountA - amountB;
      });
    } else {
      sortedArray = [...Bundlelistall];
    }

    setSortedData(sortedArray);
  };

  useEffect(() => {
    const savedSortOption = localStorage.getItem("sortOption");
    if (savedSortOption) {
      setSortOption(savedSortOption);
      sortbychange({ target: { value: savedSortOption } });
    }
  }, []);
  const columns = [
    {
      name: "User Name",
      selector: (row) => row?.userId?.firstName,
      cell: (data) => (
        <Link to={"user-details?id=" + data?._id}>
          {data?.userId
            ? data?.userId?.firstName + " " + data?.userId?.lastName
            : "Guest User"}
        </Link>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: "User Email",
      selector: (row) => row?.userId?.email,
      cell: (data) => (data?.userId ? data?.userId?.email : "Guest User"),
      sortable: true,
      width: "250px",
    },
    {
      name: "Bundle Name",
      selector: (row) => row?.bundleId?.name,
      cell: (data) => (data?.bundleId ? data?.bundleId?.name : "Guest User"),
      sortable: true,
      width: "150px",
    },
    {
      name: "Current Balance",
      selector: (row) => row.userId?.wallet,
      cell: (data) => (data?.userId ? data?.userId?.wallet : ""),
      sortable: true,
      width: "150px",
    },
    {
      name: "Date of Purchase",
      selector: (data) =>
        data?.createdAt
          ? format(new Date(data?.createdAt), "Do MMM YYYY, HH:mm A")
          : "",
      sortable: true,
      // width: "150px",
      sortFunction: (rowA, rowB) => {
        const dateA = new Date(rowA.createdAt);
        const dateB = new Date(rowB.createdAt);
        return dateA - dateB;
      },
    },
    {
      name: "Amount Paid",
      selector: (row) => row?.paymentId?.amount,
      cell: (data) => (data?.paymentId ? data?.paymentId.amount : ""),
      sortable: true,
      width: "150px",
    },
  ];
  return (
    <>
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      All Bundle List
                    </h3>
                    <div className="nk-block-des text-soft">
                      <p>
                        You have total{" "}
                        {totalList === 1
                          ? totalList + "Bundle"
                          : totalList + " " + "Bundles"}
                        .
                      </p>
                    </div>
                  </div>
                  {/* <div className="nk-block-head-content">
                    <button
                      className="btn btn-white btn-outline-light"
                      onClick={() => sortbychange("All")}
                    >
                      Sort All
                    </button>
                  </div> */}
                </div>
              </div>
              {/* <div className="nk-block">
                <div className="card card-bordered card-stretch">
                  <div className="card-inner-group">
                    <div className="card-inner position-relative card-tools-toggle">
                      <div
                        className={"card-search search-wrap active"}
                        data-search="search"
                      >
                        <div
                          className="card-body"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            className="search-content"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              padding: "0px",
                            }}
                          >
                            <form
                              className="search-content"
                              onSubmit={(e) => {
                                handleSubmitSearch(e);
                              }}
                              style={{
                                display: "flex",
                                justifyContent: "start",
                              }}
                            >
                              <input
                                type="text"
                                onChange={(e) => setSearch(e.target.value)}
                                name="search"
                                id="search"
                                className="form-control border-transparent form-focus-none"
                                placeholder="Search by user cardbalance/email..."
                                style={{
                                  paddingLeft: "0px",
                                  textTransform: "none",
                                  width: "calc(100% - 400px)",
                                }}
                              />

                              <button className="search-submit btn btn-icon">
                                <em className="icon ni ni-search"></em>
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-inner p-0">
                      <div className="nk-tb-list nk-tb-ulist">
                        <div className="nk-tb-item nk-tb-head">
                          <div
                            className="nk-tb-col nk-tb-col-check"
                            style={{ width: "0px", padding: "10px" }}
                          >
                            Sr.No
                          </div>
                          <div
                            className="nk-tb-col"
                            onClick={() => sortbychange("userName")}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="sub-text text-center">
                              User Name
                            </span>
                          </div>
                          <div
                            className="nk-tb-col tb-col-mb"
                            onClick={() => sortbychange("userEmail")}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="sub-text text-center">
                              User Email
                            </span>
                          </div>

                          <div className="nk-tb-col tb-col-md">
                            <span className="sub-text text-center">
                              Bundle Name
                            </span>
                          </div>
                          <div
                            className="nk-tb-col tb-col-md"
                            onClick={() => sortbychange("cardbalance")}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="sub-text text-center">
                              Current Balance
                            </span>
                          </div>

                          <div
                            className="nk-tb-col tb-col-md"
                            onClick={() => sortbychange("Date")}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="sub-text text-center">
                              Date of Purchase
                            </span>
                          </div>

                          <div
                            className="nk-tb-col tb-col-md"
                            onClick={() => sortbychange("amountPaid")}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="sub-text text-center">
                              Amount Paid
                            </span>
                          </div>
                        </div>
                        {loader ? (
                          <LoadingOverlay className="loaderDiv">
                            <Loader loading={loader} />
                          </LoadingOverlay>
                        ) : null}

                        {sortedData?.length > 0 ? (
                          sortedData?.map((data, index) => (
                            <div className="nk-tb-item" key={index}>
                              <div
                                className="nk-tb-col nk-tb-col-check"
                                style={{ width: "0px", padding: "10px" }}
                              >
                                {index + 1}
                              </div>
                              <div className="nk-tb-col">
                                <div className="user-info">
                                  <span
                                    className="tb-lead"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    <Link to={"user-details?id=" + data?._id}>
                                      {data?.userId
                                        ? data?.userId?.firstName +
                                          " " +
                                          data?.userId?.lastName
                                        : "Guest User"}
                                    </Link>
                                  </span>
                                </div>
                              </div>

                              <div className="nk-tb-col tb-col-md">
                                <span
                                  className="tb-lead text-center"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {data?.userId
                                    ? data?.userId?.email
                                    : "Guest User"}
                                </span>
                              </div>

                              <div className="nk-tb-col tb-col-mb">
                                <span
                                  className="tb-lead text-center"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {data?.bundleId
                                    ? data?.bundleId?.name
                                    : "Guest User"}
                                </span>
                              </div>
                              <div className="nk-tb-col tb-col-mb">
                                <span
                                  className="tb-lead text-center"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {data?.userId ? data?.userId?.wallet : ""}
                                </span>
                              </div>
                              <div className="nk-tb-col tb-col-md">
                                <span className="tb-lead text-center">
                                  {data?.createdAt
                                    ? format(
                                        new Date(data?.createdAt),
                                        "Do MMM YYYY, HH:mm A"
                                      )
                                    : ""}
                                </span>
                              </div>

                              <div className="nk-tb-col tb-col-mb">
                                <span
                                  className="tb-lead text-center"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {data?.paymentId
                                    ? data?.paymentId.amount
                                    : ""}
                                </span>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              position: "absolute",
                              left: "0px",
                              backgroundColor: "#fff",
                            }}
                          >
                            <h1
                              style={{
                                width: "100%",
                                textAlign: "center",
                                fontSize: "16px",
                                lineHeight: "61px",
                                color: "#eb5757",
                                fontWeight: "600",
                              }}
                            >
                              *Not Found
                            </h1>
                          </div>
                        )}
                      </div>
                    </div>

                    {paginationIndex ? (
                      <div className="card-inner">
                        <ReactPaginate
                          forcePage={searchPage}
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={remainder >= 1 ? divisor + 1 : divisor}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={limitData}
                          onPageChange={(e) => handlePageClick(e)}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div> */}
              <MyTable columns={columns} tabledata={allData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Bundlelist;
